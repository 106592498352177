.fleet-alerts-page-container {
    margin: 1em;
    background-color: white;
}

.fleet-alerts-tabs {
    padding: .25em 0;
}

.fleet-alerts-tab-container {
    background-color: white;
    padding: 1em;
}