.cylinder-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;

  .cylinder-data {
    position: absolute;
    z-index: 99;
    left: 25%;
    color: white;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    pointer-events: none;
  }

  img:hover {
    opacity: .8;
  }
}

.cylinder-container.selected {
  img:hover {
    opacity: 1;
  }
}

.cylinder-container.disabled {
  :hover {
    opacity: 1;
  }

  cursor: default;
}

.even-throw.cylinder-container {
  justify-content: flex-start;

  img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}