@import 'src/styles/colors.scss';

.variable-status-good {
  @include filter-ariel-green;
}

.variable-status-unnecessary {
  @include filter-ariel-yellow;
}

.variable-status-missing {
  @include filter-ariel-red;
}
.valve-status-disconnected {
  border-radius: 50%;
  background-color: lightgray;
}

.variable-status-icon {
  width: 2em;
  height: 2em;
}