
  .fleet-data-quality-report-container {
    margin: 1em;
    display: flex;
    flex-flow: column;
    justify-content: center;
    padding-bottom: 1em;

    .e-card {
      gap:1em;
      padding: 1em;

      .table-header{
        display: flex;
        justify-content: space-between;
      }
    }
    
    .auto-complete-container {
      display: flex;
      width: auto;
      align-self: center;
      margin-bottom: 1em;
      
      .auto-complete-width {
        width: 40em;
      }
    }
  }
