
$max-width-breakpoint: 2200px;


.data-quality-tab-container {
    display: flex;
    flex-direction: row;
    padding: 1em 2em 1em 1em;
    overflow-y: auto;
    gap:2em;

    @media (max-width: $max-width-breakpoint) {
        flex-direction: column;
    }
    
    .data-quality-diagram-wrapper{
        display: flex;
        flex-direction: column;
        flex:1;
        .data-quality-diagram{
            width: 100%;
            align-self: center;
            flex-direction: column;
            align-content: center;
            justify-items: center;
            position: sticky;
            top: 0%;
    
            @media (max-width: $max-width-breakpoint) {
                max-width: 80%;
            }
        }   
        .diagram-title {
            text-align: center;
            color: rgba(0, 0, 0, 0.54);
        }
        .diagram-subtitle {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: .5em;
            color: rgba(0, 0, 0, 0.54);
        }
    }
}